import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { AnimatePresence } from "framer-motion";

import MobileMenu from "./MobileMenu";

import AppLogo from "../../public/assets/img/app-logo.png";
import profilePic from "../../public/assets/img/profile-placeholder.png";

import store from "store2";

import { useRouter } from "next/router";

const UserSession = store.get("UserSession");

function HeaderNav(props) {
  const activeRouter = useRouter();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <header
      id="main-navigation"
      className="fixed top-0 left-0 right-0 z-50 bg-white"
    >
      <div className="max-w-[1800px] mx-auto sm:px-6 px-5 py-3">
        <nav id="main-nav" className="relative flex items-center">
          <div className="items-center flex-shrink-0 mr-3">
            <Link href="/">
              <a className="app-logo block w-full xl:max-w-[80px] lg:max-w-[70px] max-w-[60px]">
                <Image src={AppLogo} alt="AppLogo" />
              </a>
            </Link>
          </div>

          <div id="main-nav-items" className="xl:flex hidden items-center">
            <Link href="/">
              <a
                className={
                  activeRouter.pathname == "/"
                    ? "nav-item nav-active text-base text-blue"
                    : "nav-item text-base text-gray-700"
                }
              >
                ����������Ƶ
              </a>
            </Link>
            <Link href="/about">
              <a
                className={
                  activeRouter.pathname == "/about"
                    ? "nav-item nav-active text-base text-blue"
                    : "nav-item text-base text-gray-700"
                }
              >
                About
              </a>
            </Link>

            <div className="relative group">
              <div className="relative">
                <div
                  className={
                    activeRouter.pathname == "/eard" ||
                    activeRouter.pathname == "/cisd"
                      ? "nav-item-parent nav-item text-base text-blue"
                      : "nav-item-parent nav-item text-base text-gray-700"
                  }
                >
                  Programs & Services
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-[18px] w-[18px] absolute top-[13px] right-[6px]"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              {/* bg-divide-y divide-gray-100 rounded-2xl items-center absolute top-5 shadow-md bg-white  max-w-xl */}
              <div className="absolute bg-white p-5 rounded-md invisible group-hover:visible shadow-lg">
                <Link href="/eard">
                  <a className="sub-nav-item text-base text-gray-700">
                    Education, Advocacy, and Research
                  </a>
                </Link>
                <Link href="/cisd">
                  <a className="sub-nav-item text-base text-gray-700">
                    Clinical and Intervention
                  </a>
                </Link>
              </div>
            </div>

            <Link href="/news-events?page=1">
              <a
                className={
                  activeRouter.pathname == "/news-events"
                    ? "nav-item nav-active text-base text-blue"
                    : "nav-item text-base text-gray-700"
                }
              >
                ����������Ƶ
              </a>
            </Link>
            <Link href="/membership">
              <a
                className={
                  activeRouter.pathname == "/membership"
                    ? "nav-item nav-active text-base text-blue"
                    : "nav-item text-base text-gray-700"
                }
              >
                Membership
              </a>
            </Link>
            <Link href="/careers">
              <a
                className={
                  activeRouter.pathname == "/careers"
                    ? "nav-item nav-active text-base text-blue"
                    : "nav-item text-base text-gray-700"
                }
              >
                Careers
              </a>
            </Link>
            <Link href="/contact">
              <a
                className={
                  activeRouter.pathname == "/contact"
                    ? "nav-item nav-active text-base text-blue"
                    : "nav-item text-base text-gray-700"
                }
              >
                Contact
              </a>
            </Link>
          </div>

          <div className="absolute right-0">
            <div id="nav-right-menu" className="flex items-center justify-end">
              <div className="text-right">
                <div className="sm:inline-block hidden align-middle px-4 border-r">
                  <Link href="/booking">
                    <a
                      className={
                        activeRouter.pathname == "/booking"
                          ? "nav-item nav-active text-base text-blue"
                          : "nav-item text-base text-gray-700"
                      }
                    >
                      Book Now
                    </a>
                  </Link>
                </div>
                <div className="inline-block align-middle px-4 border-r">
                  <Link href="/donations">
                    <a
                      className={
                        activeRouter.pathname == "/donations"
                          ? "nav-item nav-active text-base text-blue"
                          : "nav-item text-base text-gray-700"
                      }
                    >
                      Donate
                    </a>
                  </Link>
                </div>
                <div className="inline-block align-middle pl-4">
                  {UserSession != null ? (
                    <>
                      <div className="bg-gray-300 rounded-full p-[2px] w-[35px] h-[35px]">
                        <Link href="/app/account/onboarding">
                          <a className="">
                            <Image
                              src={profilePic}
                              alt="Profile Pic"
                              className="rounded-full"
                            />
                          </a>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <Link href="/account/login">
                      <a className="nav-item text-base text-gray-700">Login</a>
                    </Link>
                  )}
                </div>
              </div>
              {/* Mobile - Menu Bar */}
              <div id="mobile-menu" className="xl:hidden block ml-4">
                <div className="donate-button mr-4 hidden">
                  <Link href="/donations">
                    <a className="">
                      {UserSession != null ? (
                        <>
                          {UserSession?.result?.user_role_id == 1 ? (
                            <Link href="/app/dashboard">
                              <a className="text-sm text-white hover: bg-blue rounded-md pt-[8px] pb-[10px] px-[16px] uppercase tracking-wider">
                                Dashboard
                              </a>
                            </Link>
                          ) : (
                            <>
                              {UserSession?.result?.user_role_id == 7 ||
                              UserSession?.result?.user_role_id == 10 ? (
                                <Link href="/app/doctors/dashboard">
                                  <a className="text-sm text-white hover: bg-blue rounded-md pt-[8px] pb-[10px] px-[16px] uppercase tracking-wider">
                                    Dashboard
                                  </a>
                                </Link>
                              ) : (
                                <>
                                  {UserSession?.result?.user_role_id == 8 ? (
                                    <Link href="/app/patients/dashboard">
                                      <a className="text-sm text-white hover: bg-blue rounded-md pt-[8px] pb-[10px] px-[16px] uppercase tracking-wider">
                                        Dashboard
                                      </a>
                                    </Link>
                                  ) : (
                                    <>
                                      {UserSession?.result?.user_role_id ==
                                      3 ? (
                                        <Link href="/app/hr/dashboard">
                                          <a className="text-sm text-white hover: bg-blue rounded-md pt-[8px] pb-[10px] px-[16px] uppercase tracking-wider">
                                            Dashboard
                                          </a>
                                        </Link>
                                      ) : (
                                        <>
                                          {UserSession?.result?.user_role_id ==
                                          6 ? (
                                            <Link href="/app/employee/dashboard">
                                              <a className="text-sm text-white hover: bg-blue rounded-md pt-[8px] pb-[10px] px-[16px] uppercase tracking-wider">
                                                Dashboard
                                              </a>
                                            </Link>
                                          ) : (
                                            <>
                                              <Link href="/app/dashboard">
                                                <a className="text-sm text-white hover: bg-blue rounded-md pt-[8px] pb-[10px] px-[16px] uppercase tracking-wider">
                                                  Dashboard
                                                </a>
                                              </Link>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Link href="/account/login">
                            <a className="text-sm text-white hover: bg-blue rounded-md pt-[8px] pb-[10px] px-[16px] uppercase tracking-wider">
                              Login
                            </a>
                          </Link>
                        </>
                      )}
                    </a>
                  </Link>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setMobileMenuOpen(!isMobileMenuOpen);
                  }}
                  className="inline-flex items-center justify-center py-2 rounded-md hover:bg-transparent focus:bg-transparent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  {/* <!--
                                        Icon when menu is closed.
                                        Heroicon name: outline/menu
                                        Menu open: "hidden", Menu closed: "block"
                                    --> */}
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  {/* <!--
                                        Icon when menu is open.
                                        Heroicon name: outline/x
                                        Menu open: "block", Menu closed: "hidden"
                                    --> */}
                </button>
              </div>
            </div>

            {/* <div className='inline-block'>
                            <div>
                                <div className="text-base lg:text-sm xl:text-base text-white lg:text-blue-900 block mb-3 lg:mb-0 lg:inline-block lg:mt-0 group-hover:border-grey-light">
                                    <div className="relative group hidden">
                                        <div className="flex items-center gap-2">
                                            <span className="">Account</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <div className="items-center absolute p-8 -left-36 shadow-md bg-white invisible group-hover:visible w-60 divide-y divide-gray-100 rounded-2xl">
                                            <div>
                                                <Link href="/account/login"><a className="pb-3 block text-black hover:bg-grey-lighter">Login</a></Link>
                                                <Link href="/account/register"><a className="pb-5 block text-black hover:bg-grey-lighter">Create an account</a></Link>
                                            </div>
                                            <div className="hidden">
                                                <Link href="#"><a className="pt-5 block text-black hover:bg-grey-lighter">Be a member</a></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>

          <AnimatePresence>
            {isMobileMenuOpen && (
              <MobileMenu setMobileMenuOpen={setMobileMenuOpen} />
            )}
          </AnimatePresence>
        </nav>
      </div>
    </header>
  );
}

export default HeaderNav;
